'use client';

import React, { useContext, useReducer } from 'react';

const initialState: StorefrontContextState =
{
  miniCartOpen: false,
  cartConfirmOpen: false
}

type StorefrontContextState = {
  miniCartOpen: boolean;
  cartConfirmOpen: boolean;
  currentVariant?: string;
}

export type StorefrontAction =
  | { type: 'OPEN_MINI_CART' }
  | { type: 'CLOSE_MINI_CART' }
  | { type: 'OPEN_CART_CONFIRM', currentVariant?: string }
  | { type: 'CLOSE_CART_CONFIRM' }

const StorefrontContext = React.createContext<StorefrontContextState>(initialState)
const StorefrontDispatchContext = React.createContext<React.Dispatch<StorefrontAction>>(() => { });

export interface StorefrontProviderProps {
  children: React.ReactNode
}

export function StorefrontProvider({
  children,
}: StorefrontProviderProps) {
  const [storefrontContext, dispatch] = useReducer(storefrontReducer, initialState);
  return (
    <StorefrontContext.Provider value={storefrontContext}>
      <StorefrontDispatchContext.Provider value={dispatch}>
        {children}
      </StorefrontDispatchContext.Provider>
    </StorefrontContext.Provider>
  )
}

export function useStorefront() {
  return useContext(StorefrontContext);
}

export function useStorefrontDispatch() {
  return useContext(StorefrontDispatchContext);
}

function storefrontReducer(state: StorefrontContextState, action: StorefrontAction): StorefrontContextState {
  switch (action.type) {
    case 'OPEN_MINI_CART': {
      return { ...state, miniCartOpen: true };
    }
    case 'CLOSE_MINI_CART': {
      return { ...state, miniCartOpen: false };
    }
    case 'OPEN_CART_CONFIRM': {
      if (state.miniCartOpen)
        return state;

      return { ...state, cartConfirmOpen: true, currentVariant: action.currentVariant };
    }
    case 'CLOSE_CART_CONFIRM': {
      return { ...state, cartConfirmOpen: false, currentVariant: undefined };
    }
    default: {
      throw Error('Unknown StorefrontDispatchContext action');
    }
  }
}