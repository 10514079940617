'use client';
import Script from 'next/script';

export default function StampedScript({ apiKey, sId }: { apiKey?: string, sId?: string }) {
  if (apiKey && sId) {
    return (
      <Script
        src='https://cdn1.stamped.io/files/widget.min.js'
        onLoad={() => {
          if (typeof window === 'undefined' || !window.StampedFn) return;
          window.StampedFn.init({ apiKey: `${apiKey}`, sId: `${sId}` });
        }}
      />
    );
  }
}
