'use client'

import { useEffect } from 'react';

export function NavigationEvents() {
  useEffect(() => {
    const originalPushState = window.history.pushState.bind(window.history)

    window.history.pushState = function pushState(
      data: any,
      _unused: string,
      url?: string | URL | null
    ): void {
      window.dispatchEvent(new Event('pushstate'));
      originalPushState(data, _unused, url);
    }

    return () => {
      window.history.pushState = originalPushState
    }
  }, [])

  return null;
}
